<template>
  <div class="table-container">
    <v-data-table
      sort-by="purchaseDate"
      :loading="findLoading"
      :options="{ itemsPerPage: -1 }"
      :headers="headers"
      :items="filteredResults">
      <template v-slot:item.purchaseDate="{ item }">
        <span v-if="item">{{ purchaseFormattedDate(item.purchaseDate) }}</span>
      </template>
      <template v-slot:item.marianaCustomerName="{ item }">
        <a v-if="item" :href="`https://rayoga.marianatek.com/admin/user/profile/${item.marianaCustomerId}/payment-methods`" target="_blank">
          {{ item.marianaCustomerName }}
        </a>
      </template>
      <template v-slot:item.open="{ item }">
        <v-btn
          v-if="item"
          class="my-4"
          depressed
          block
          color="primary"
          @click="openCommentDialog(item)"
          >
          Start
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="itemDialog"
      v-if="itemDialog"
      fullscreen
    >
      <v-card class="pa-5">
        <v-container>
          <v-card-title>
            <h1 class="pb-5">{{ dialogItem.marianaCustomerName }}</h1>
          </v-card-title>

          <v-card-text>
            <v-row align="end">
              <v-col class="py-3">
                <label></label>
                <h2><a :href="`https://rayoga.marianatek.com/admin/user/profile/${dialogItem.marianaCustomerId}/payment-methods`" target="_blank">View Customer Profile</a></h2>
              </v-col>
              <v-col class="py-3">
                <label>Phone Number:</label>
                <h2>{{ (dialogItem.marianaCustomerPhoneNumber) ? dialogItem.marianaCustomerPhoneNumber : `Not on file` }}</h2>
              </v-col>
              <v-col class="py-3">
                <label>Email:</label>
                <h2>{{ dialogItem.marianaCustomerEmail }}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="py-4" />
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  class="pt-6"
                  outlined
                  clearable
                  label="First Class Location"
                  :value="dialogItem.firstClassLocation"
                  :items="firstClassLocationOptions"
                  @change="updateFirstClassLocation($event, dialogItem.id)"
                />
              </v-col>
              <v-col>
                <v-select
                  class="pt-6"
                  outlined
                  clearable
                  label="Purchase Type"
                  :value="dialogItem.purchaseType"
                  :items="purchaseTypeOptions"
                  @change="updatePurchaseType($event, dialogItem.id)"
                />
              </v-col>
              <v-col>
                <v-select
                  class="pt-6"
                  outlined
                  clearable
                  label="Called By"
                  :value="dialogItem.calledBy"
                  :items="calledByOptions"
                  @change="updateCalledBy($event, dialogItem.id)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  class="pt-6"
                  outlined
                  label="Call Completed?"
                  :value="dialogItem.callCompletedString"
                  :items="callCompletedOptions"
                  @change="updateCallCompleted($event, dialogItem.id)"
                />
              </v-col>
              <v-col>
                <v-select
                  class="pt-6"
                  outlined
                  clearable
                  label="Call Result"
                  :value="dialogItem.callResult"
                  :items="callResultOptions"
                  @change="updateCallResult($event, dialogItem.id)"
                />
              </v-col>
              <v-col class="text-center">
                <label>Rating by Customer</label>
                <v-rating
                  v-model="dialogItem.ratingByCustomer"
                  @input="updateRatingByCustomer($event, dialogItem.id)"
                  background-color="orange lighten-3"
                  color="orange"
                  clearable
                ></v-rating>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Comments"
                  v-model="dialogItem.firstCallComments">
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row justify="end">
              <v-col cols="4">
                <v-btn
                  depressed
                  x-large
                  color="gray"
                  block
                  @click="closeDialog">
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  depressed
                  x-large
                  color="success"
                  block
                  @click="submitItem($event, dialogItem.id)">
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  name: 'FiveDayTable',

  props: [
    'customers'
  ],

  data() {
    return {
      itemDialog: false,
      dialogItem: null,
      tempFirstCallComments: null,
      headers: [
        {
          text: 'Purchase Date',
          value: 'purchaseDate',
        },
        {
          text: 'Customer',
          value: 'marianaCustomerName',
        },
        {
          text: 'Phone Number',
          value: 'marianaCustomerPhoneNumber'
        },
        {
          text: '',
          value: 'open'
        },
      ],
      firstClassLocationOptions: [
      {
          text: 'Costa Mesa',
          value: 'Costa Mesa',
        },
        {
          text: 'Long Beach',
          value: 'Long Beach',
        },
        {
          text: 'Newport Beach',
          value: 'Newport Beach',
        },
      ],
      purchaseTypeOptions: [
      {
          text: 'In Studio',
          value: 'In Studio',
        },
        {
          text: 'Web (ECCO)',
          value: 'Web (ECCO)',
        },
        {
          text: 'Self Bought',
          value: 'Self Bought',
        },
      ],
      callCompletedOptions: [
        {
          text: 'No',
          value: 'No'
        },
        {
          text: 'Yes',
          value: 'Yes',
        },
        {
          text: 'N/A',
          value: 'N/A',
        },
      ],
      calledByOptions: [
        /*{
          text: 'Aly',
          value: 'Aly',
        },*/
        {
          text: 'Alexa',
          value: 'Alexa',
        },
        {
          text: 'Jona',
          value: 'Jona',
        },
        {
          text: 'Kailani',
          value: 'Kailani',
        },
        {
          text: 'Larissa',
          value: 'Larissa',
        },
        {
          text: 'Lexi',
          value: 'Lexi',
        },
        {
          text: 'Lindsey',
          value: 'Lindsey',
        },
        {
          text: 'Matthew',
          value: 'Matthew',
        },
        {
          text: 'Mona',
          value: 'Mona',
        },
        {
          text: 'Rhianna',
          value: 'Rhianna',
        },
        {
          text: 'Other FD',
          value: 'Other FD',
        },
      ],
      callResultOptions: [
        {
          text: 'Spoke to Client',
          value: 'Spoke to Client',
        },
        {
          text: 'Left Message',
          value: 'Left Message',
        },
        {
          text: 'Other',
          value: 'Other',
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      introOfferCustomers: 'intro-offer-customer/list'
    }),

    selectedStudio() {
      return this.$store.state.meta.introOfferSelectedStudio
    },

    findLoading() {
      return this.$store.state['intro-offer-customer'].isFindPending
    },

    filteredResults() {
      let filtered = this.introOfferCustomers

      filtered = filtered.filter(o => o.purchaseLocation === this.selectedStudio)
      filtered = filtered.filter(o => o.purchaseDate <= DateTime.now().minus({ 'day': 6 }).toISO())
      filtered = filtered.filter(o => o.fiveDayCompleted === false)

      return filtered
    }
  },

  watch: {
    selectedStudio() {
      this.$nextTick(() => {
        this.findIntroOfferCustomers()
      })
    }
  },

  methods: {
    openCommentDialog(item) {
      this.itemDialog = true
      this.dialogItem = item
    },
    closeDialog() {
      this.dialogItem = null
      this.itemDialog = false
    },
    purchaseFormattedDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
    },
    calledAtFormattedDate(date) {
      if (date) {
        return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
      } else {
        return 'Not Called'
      }
    },
    async findIntroOfferCustomers() {
      await this.$store.commit('intro-offer-customer/clearAll', this.$store.state['intro-offer-customer'])
      await this.$store.dispatch('intro-offer-customer/find', {
        query: {
          $limit: 500,
          purchaseLocation: this.selectedStudio,
          purchaseDate: {
            $lte: DateTime.now().startOf('day').minus({ 'day': 6 }).toISO()
          },
          fiveDayCompleted: {
            $ne: true,
          }
        }
      })
    },
    async updateFirstClassLocation(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { firstClassLocation: item }])
    },
    async updatePurchaseType(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { purchaseType: item }])
    },
    async updateCalledBy(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { calledBy: item }])
    },
    async updateCallCompleted(item, id) {
      let date = DateTime.now().toISO()
      if (!item) date = null
      this.$store.dispatch('intro-offer-customer/patch', [id, { callCompleted: (item) ? true : false, callCompletedDate: date }])
    },
    async updateCallResult(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { callResult: item }])
    },
    async updateRatingByCustomer(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { ratingByCustomer: item }])
    },
    async updateFirstCallComments() {
      this.$store.dispatch('intro-offer-customer/patch', [this.commentsDialogItem.id, { firstCallComments: this.tempFirstCallComments }])
      this.tempFirstCallComments = null
      this.commentsDialogItem = null
      this.commentsDialog = false
    },
    async submitItem(item, id) {
      this.closeDialog()
      await this.$store.dispatch('intro-offer-customer/patch', [id, { fiveDayCompleted: true }])
      this.findIntroOfferCustomers()
    }
  },

  mounted() {
    this.findIntroOfferCustomers()
  },

  destroyed() {
    this.$store.commit('intro-offer-customer/clearAll', this.$store.state['intro-offer-customer'])
  }
}
</script>